.nextButton {
    display: block;
    background: #43e569;
    border: none;
    outline: none;
    cursor: pointer;
    zoom: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 50%;
    z-index: 1;
}

.prevButton {
    display: block;
    background: #43e569;
    border: none;
    outline: none;
    zoom: 80%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 50%;
}

.prevButton:hover, .nextButton:hover {
    background: #136828;
}

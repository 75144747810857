.nextButton {
    position: absolute;
    top: 50%;
    right: 0;
    background: #43e569;
    transform: translateY(-50%);
  }
  
 .prevButton {
    position: absolute;
    top: 50%;
    z-index: 2;
    left: 0;
    background: #43e569;
    transform: translateY(-50%);
  }


@tailwind base;
@tailwind components;
@tailwind utilities;

.react-multiple-carousel__arrow .react-multiple-carousel__arrow--left {
    background: #000 !important;
}
html, body {
  
    background-color: rgb(244 244 245 ) !important;
   
}
.react-multiple-carousel__arrow .react-multiple-carousel__arrow--right {
    background: #000 !important;
}
